import {
  MY_CONTENT_LOADING,
  MY_CONTENT_LOADED,
  MY_CONTENT_FAILED,
  MY_CONTENT_ITEM_UPDATED,
  MY_CONTENT_ITEM_REMOVED,
  SET_HIDE_MY_CONTENT_NOTIFICATION,
  MY_CONTENT_CLEARED,
  MY_CONTENT_PAGE_LOADING,
  MY_CONTENT_PAGE_LOADED
} from './actions'
const initialState = { favourites: [], hideNotification: 'unknown' }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MY_CONTENT_LOADING: {
      return { ...state, loading: action.loading, error: null }
    }
    case MY_CONTENT_LOADED: {
      return {
        ...state,
        favourites: [...action.payload.favourites],
        _links: action.payload._links,
        loading: action.loading
      }
    }
    case MY_CONTENT_FAILED: {
      return { ...initialState, loading: action.loading, error: action.error.toString() }
    }
    case MY_CONTENT_CLEARED: {
      return { ...initialState }
    }
    case MY_CONTENT_PAGE_LOADING: {
      return { ...state, pageLoading: action.loading }
    }
    case MY_CONTENT_PAGE_LOADED: {
      const { favourites = [], ...restPayload } = action.payload
      return { ...state, ...restPayload, favourites: [...state.favourites, ...favourites], pageLoading: action.loading }
    }
    case MY_CONTENT_ITEM_UPDATED: {
      return {
        ...state,
        error: null,
        favourites: state.favourites.map(favourite => {
          if (favourite._links.self.href === action.payload.selfHref) {
            return { ...favourite, ...action.payload.myContentItem }
          } else {
            return favourite
          }
        })
      }
    }
    case MY_CONTENT_ITEM_REMOVED: {
      return {
        ...state,
        error: null,
        favourites: state.favourites.filter(favourite => favourite._links.self.href !== action.payload.selfHref)
      }
    }
    case SET_HIDE_MY_CONTENT_NOTIFICATION: {
      return {
        ...state,
        hideNotification: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
