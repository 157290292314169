import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react'
import reducer from './reducer'
import {
  updateMyContentItemState as updateMyContentItemStateAction,
  removeMyContentItemFromState as removeMyContentItemFromStateAction,
  myContentLoading,
  myContentLoaded,
  myContentFailed,
  myContentPageLoading,
  myContentPageLoaded,
  myContentPageFailed
} from './actions'
import { fetchMyContent as fetchMyContentFromApi, fetchPersonalisation } from '../../apiClients/personalisation'
import { useIsLoggedIn } from '../Auth/AuthContext'

const MyContentContext = createContext()

export const MyContentProvider = ({ children }) => {
  const isLoggedIn = useIsLoggedIn()

  const [state, dispatch] = useReducer(reducer)

  const removeMyContentItemFromState = useCallback(selfHref => {
    dispatch(removeMyContentItemFromStateAction(selfHref))
  }, [])

  const updateMyContentItemState = useCallback((selfHref, myContentItem = {}) => {
    dispatch(updateMyContentItemStateAction(selfHref, myContentItem))
  }, [])

  const fetchMyContent = useCallback(() => {
    if (!isLoggedIn) return
    const myContentAbortController = new AbortController()
    dispatch(myContentLoading())
    const loadDataPromise = fetchMyContentFromApi({ signal: myContentAbortController.signal })

    loadDataPromise
      .then(myContent => {
        dispatch(myContentLoaded(myContent))
      })
      .catch(error => {
        dispatch(myContentFailed(error))
      })

    return {
      loadDataPromise,
      abort: () => {
        myContentAbortController.abort()
      }
    }
  }, [isLoggedIn])

  const fetchNextPage = useCallback(() => {
    if (!isLoggedIn) return
    const nextPageHref = state._links?.next?.href
    if (!nextPageHref) return
    const myContentAbortController = new AbortController()
    dispatch(myContentPageLoading())
    const loadDataPromise = fetchPersonalisation(nextPageHref, { signal: myContentAbortController.signal })

    loadDataPromise
      .then(myContent => {
        dispatch(myContentPageLoaded(myContent))
      })
      .catch(error => {
        dispatch(myContentPageFailed(error))
      })

    return {
      loadDataPromise,
      abort: () => {
        myContentAbortController.abort()
      }
    }
  }, [state, isLoggedIn])

  const value = useMemo(() => {
    return {
      removeMyContentItemFromState,
      updateMyContentItemState,
      fetchMyContent,
      fetchNextPage,
      ...state
    }
  }, [removeMyContentItemFromState, updateMyContentItemState, fetchMyContent, fetchNextPage, state])
  return <MyContentContext.Provider value={value}>{children}</MyContentContext.Provider>
}

MyContentProvider.propTypes = {
  children: PropTypes.node
}

export const useRemoveMyContentItemFromState = () => {
  const { removeMyContentItemFromState } = useContext(MyContentContext)
  return removeMyContentItemFromState
}

export const useUpdateMyContentItemState = () => {
  const { updateMyContentItemState } = useContext(MyContentContext)
  return updateMyContentItemState
}

export const useFetchMyContent = () => {
  const { fetchMyContent } = useContext(MyContentContext)
  return fetchMyContent
}

export const useFetchNextPage = () => {
  const { fetchNextPage } = useContext(MyContentContext)
  return fetchNextPage
}

const createPageLink = favourite => {
  const { _embedded, favouriteContentType } = favourite
  const [seriesId] = _embedded[favouriteContentType]._links.self.href.split('/').reverse()
  const contentTypeUrlPart = favouriteContentType === 'podcast' ? '/podkast' : '/serie'
  return `${contentTypeUrlPart}/${seriesId}`
}

const mapFavourites = ({ favourites = [] }) => {
  return favourites.map(favourite => {
    const embedded = favourite._embedded?.[favourite.favouriteContentType] || {}
    const squareimage = embedded.squareImage || []
    const squareImageUrl = squareimage.find(({ width }) => width >= 400)?.url
    const image = embedded.image || []
    const imageUrl = image.find(({ width }) => width >= 400)?.url
    const title = embedded.titles?.title
    const linkUrl = createPageLink(favourite)
    return { ...favourite, imageUrl, squareImageUrl, title, linkUrl }
  })
}

export const useMyContentHaveFavorites = () => {
  const { favourites } = useContext(MyContentContext)
  return !!favourites?.length
}

export const useMyContentFavourites = () => {
  const favourites = mapFavourites(useContext(MyContentContext))
  return favourites
}

export const useMyContentFavouritesFirstPage = () => {
  const favourites = mapFavourites(useContext(MyContentContext))
  return favourites.slice(0, 20)
}

export const useMyContentFavouritesNextPageUrl = () => {
  const { _links } = useContext(MyContentContext)
  return _links?.next?.href
}

export const useMyContentIsLoading = () => {
  const { loading } = useContext(MyContentContext)
  return !!loading
}

export const useMyContentPageIsLoading = () => {
  const { pageLoading } = useContext(MyContentContext)
  return !!pageLoading
}

export const useMyContentHasError = () => {
  const { error } = useContext(MyContentContext)
  return !!error
}

export const useIsLoginNotificationHidden = () => {
  const { hideNotification } = useContext(MyContentContext)
  return !!hideNotification
}
