import React from 'react'
import AspectRatioBox from '../../AspectRatioBox'
import LoadingPlaceholder from '../../LoadingPlaceholder/LoadingPlaceholder'
import PseudoBox from '../../PseudoBox'

const MyContentLoadingPlug = props => {
  return (
    <PseudoBox pos="relative" flex="0 0 auto" w="full" {...props}>
      <AspectRatioBox ratio={1 / 1}>
        <LoadingPlaceholder w="100%" h="auto" borderRadius="md" />
      </AspectRatioBox>
      <LoadingPlaceholder w="100%" h={5} mt={4} />
    </PseudoBox>
  )
}

MyContentLoadingPlug.propTypes = {}

export default MyContentLoadingPlug
