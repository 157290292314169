export const MY_CONTENT_LOADING = 'MY_CONTENT_LOADING'
export const MY_CONTENT_LOADED = 'MY_CONTENT_LOADED'
export const MY_CONTENT_FAILED = 'MY_CONTENT_FAILED'

export const MY_CONTENT_PAGE_LOADING = 'MY_CONTENT_PAGE_LOADING'
export const MY_CONTENT_PAGE_LOADED = 'MY_CONTENT_PAGE_LOADED'
export const MY_CONTENT_PAGE_FAILED = 'MY_CONTENT_PAGE_FAILED'

export const MY_CONTENT_CLEARED = 'MY_CONTENT_CLEARED'
export const MY_CONTENT_ITEM_UPDATED = 'MY_CONTENT_ITEM_UPDATED'
export const MY_CONTENT_ITEM_REMOVED = 'MY_CONTENT_ITEM_REMOVED'
export const SET_HIDE_MY_CONTENT_NOTIFICATION = 'SET_HIDE_MY_CONTENT_NOTIFICATION'

export const myContentLoading = () => {
  return {
    type: MY_CONTENT_LOADING,
    loading: true
  }
}

export const myContentLoaded = payload => {
  return {
    type: MY_CONTENT_LOADED,
    loading: false,
    payload
  }
}

export const myContentFailed = error => {
  return {
    type: MY_CONTENT_FAILED,
    loading: false,
    error
  }
}

export const myContentPageLoading = () => {
  return {
    type: MY_CONTENT_PAGE_LOADING,
    loading: true
  }
}

export const myContentPageLoaded = payload => {
  return {
    type: MY_CONTENT_PAGE_LOADED,
    loading: false,
    payload
  }
}

export const myContentPageFailed = error => {
  return {
    type: MY_CONTENT_PAGE_FAILED,
    loading: false,
    error
  }
}

export const updateMyContentItemState = (selfHref, myContentItem = {}) => {
  return {
    type: MY_CONTENT_ITEM_UPDATED,
    payload: { selfHref, myContentItem }
  }
}

export const removeMyContentItemFromState = selfHref => {
  return {
    type: MY_CONTENT_ITEM_REMOVED,
    payload: { selfHref }
  }
}
